import { Navigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

interface PrivateRouteProps {
  children: React.ReactElement;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { accounts } = useMsal();

  if (accounts.length > 0) {
    return children;
  }

  return <Navigate to="/" replace />;
};
