import { PublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider } from '@azure/msal-react';
import { PrimeReactProvider } from 'primereact/api';
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { FilterProvider } from './context/FilterContext';
import { ShoppingCartProvider } from './context/ShoppingCartContext';
import SplashScreen from './pages/splash/Splash';
// Estilos de PrimeReact y PrimeFlex
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/md-light-indigo/theme.css';
import { BusinessUnitProvider } from './components/BusinessUnit/BusinessUnitProvider';
import ChoiceCustomer from './pages/choiceCustomer/ChoiceCustomer';
import Products from './pages/home/Products';
import { PrivateRoute } from './routes/PrivateRoute';

const App: React.FC<{ instance: PublicClientApplication }> = ({ instance }) => {
  return (
    <div className="App">
      <MsalProvider instance={instance}>
        <ShoppingCartProvider>
          <PrimeReactProvider value={{ unstyled: false }}>
            <FilterProvider>
              <BusinessUnitProvider>
                <Router>
                  <Routes>
                    <Route path="*" element={<SplashScreen />} />
                    <Route
                      path="/products"
                      element={
                        <PrivateRoute>
                          <Products />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/choiceCustomer"
                      element={
                        <PrivateRoute>
                          <ChoiceCustomer />
                        </PrivateRoute>
                      }
                    />
                  </Routes>
                </Router>
              </BusinessUnitProvider>
            </FilterProvider>
          </PrimeReactProvider>
        </ShoppingCartProvider>
      </MsalProvider>
    </div>
  );
};

export default App;
